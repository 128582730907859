import { Injectable } from "@angular/core";
import { of, Subject } from "rxjs";
import { delay } from "rxjs/operators";

export enum MessageType {
    info,
    warning,
    error
}

export class Message {
    public messageType = MessageType.info;
    public id = Math.trunc(Math.random() * 10000);
    public timestamp = Date.now();
    constructor(public message: string) {}
}

export class AlertMessage extends Message {
    public confirm: () => void;
    constructor(message: string) {
        super(message);
    }
}

@Injectable({
    providedIn: "root"
})
export class HiTestMessageService {
    public readonly messageUpTime = 5000;
    private messages: Message[] = [];
    private alerts: AlertMessage[] = [];

    private messagesSubject = new Subject<Message[]>();
    public messages$ = this.messagesSubject.asObservable();

    private alertsSubject = new Subject<AlertMessage[]>();
    public alerts$ = this.alertsSubject.asObservable();
    /**
     * Publishes provided message to all observers and removes it
     * after the number of milliseconds defined in class field messageUpTime.
     * @param message The message to publish.
     */
    public sendMessage(message: string): void {
        const messageInstance = this.createMessage(message, MessageType.info);
        this.publishMessage(messageInstance);
    }

    /**
     * Publishes provided message to all observers and removes it
     * after the number of milliseconds defined in class field messageUpTime.
     * @param warning The warning message to publish.
     */
    public sendWarning(warning: string): void {
        const messageInstance = this.createMessage(warning, MessageType.warning);
        this.publishMessage(messageInstance);
    }

    public clearAlerts(): void {
        this.alerts = [];
    }

    public sendAlert(alert: string): void {
        const message = this.createMessage(alert, MessageType.error);
        const alertMessage = new AlertMessage(message.message);

        // Add a callback function to the alert message instance's confirm property.
        alertMessage.confirm = (): any => {
            const index = this.alerts.indexOf(alertMessage);
            if (index >= 0) {
                this.alerts.splice(index, 1);
                this.alertsSubject.next(this.alerts);
            }
        };

        this.alerts.push(alertMessage);
        this.alertsSubject.next(this.alerts);
    }

    private createMessage(content: string, messageType: MessageType): Message {
        let message = new Message(content);
        message.messageType = messageType;
        return message;
    }

    private async publishMessage(messageInstance: Message): Promise<void> {
        if (!this.messages.some(x => x.message === messageInstance.message)) {
            this.messages.push(messageInstance);
            this.messagesSubject.next(this.messages);
            await of({}).pipe(delay(this.messageUpTime)).toPromise();
            this.removeOldestMessage();
        }
    }

    private removeOldestMessage() {
        if (this.messages.length > 0) {
            this.messages.shift();
        }
        this.messagesSubject.next(this.messages);
    }
}
