import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { Urls } from "src/app/globals/urls";
import {    HiTestInputDTO, HiTestInputTypeDTO,
            HiTestInputUnitDTO, HiTestTableLookUpDTO,
            HiTestBenchDTO, HiTestBenchInputDTO,
            HiTestDiagnosticValue, HiTestDiagnosticValuesDTO,
            IHiTestLocation,
            ImportLookupTableResponse
        } from "../../models/hitestinputs.model";

@Injectable({
    providedIn: "root"
})

export class HiTestInputsHttpService {
    constructor(private http: HttpClient) {
    }

    public getInputs(): Observable<HiTestInputDTO[]> {
        const result = this.http.get<HiTestInputDTO[]>(Urls.HITEST_GET_INPUTS);
        return result;
    }

    public getInputNames(): Observable<string[]> {
        const result = this.http.get<string[]>(Urls.HITEST_GET_INPUT_NAMES);
        return result;
    }

    public getInputTypes(): Observable<HiTestInputTypeDTO[]> {
        const result = this.http.get<HiTestInputTypeDTO[]>(Urls.HITEST_GET_INPUT_TYPES);
        return result;
    }

    public getUnits(): Observable<HiTestInputUnitDTO[]> {
        const result = this.http.get<HiTestInputUnitDTO[]>(Urls.HITEST_GET_INPUT_UNIT_TYPES);
        return result;
    }

    public deleteInput(hitestInput: HiTestInputDTO): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = hitestInput;
        return this.http.put<boolean>(Urls.HITEST_DELETE_INPUT, body, { headers: requestHeaders });
    }

    public updateInput(hitestInput: HiTestInputDTO): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = hitestInput;
        return this.http.put<boolean>(Urls.HITEST_UPDATE_INPUT, body, { headers: requestHeaders });
    }

    public createInput(hitestInput: HiTestInputDTO): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = hitestInput;
        return this.http.put<boolean>(Urls.HITEST_ASSIGN_INPUT, body, { headers: requestHeaders });
    }

    public getTableLookUpSelection(): Observable<HiTestTableLookUpDTO[]> {
        const result = this.http.get<HiTestTableLookUpDTO[]>(Urls.HITEST_GET_TABLE_LOOKUP);
        return result;
    }

    public getLookUpTableForExport(tableName: string): Observable<string> {
        const result = this.http.get<string>(Urls.HITEST_LOOKUP_TABLE + "/table/" + tableName);
        return result;
    }

    public importLookupTableAsync(tableName: string, importedLookup: string): Promise<ImportLookupTableResponse> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = JSON.stringify(importedLookup);
        const result = firstValueFrom(
            this.http.put<ImportLookupTableResponse>(Urls.HITEST_LOOKUP_TABLE + "/table/" + tableName, body, { headers: requestHeaders })
            );
        return result;
    }

    public getLookupTableList(): Observable<string[]> {
        const result = this.http.get<string[]>(Urls.HITEST_LOOKUP_TABLE + "/tables");
        return result;
    }

    public getTestBenchInterfaces(locationId: number): Observable<HiTestBenchDTO[]> {
        const result = this.http.get<HiTestBenchDTO[]>(Urls.HITEST_TEST_BENCH_INTERFACE + "/" + locationId);
        return result;
    }

    public updateTestBench(hitestTestBench: HiTestBenchDTO): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = hitestTestBench;
        return this.http.post<boolean>(Urls.HITEST_TEST_BENCH, body, { headers: requestHeaders });
    }

    public deleteTestBench(benchId: number): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.delete<boolean>(Urls.HITEST_TEST_BENCH + "/" + benchId, { headers: requestHeaders });
    }

    public getBenchSensorInputs(locationId: number): Observable<HiTestBenchInputDTO[]> {
        const result = this.http.get<HiTestBenchInputDTO[]>(Urls.HITEST_DYNAMIC_TEST_BENCH + "/" + locationId);
        return result;
    }

    public updateBenchSensorInput(hitestBenchInput: HiTestBenchInputDTO): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.post<boolean>(Urls.HITEST_DYNAMIC_TEST_BENCH, hitestBenchInput, { headers: requestHeaders });
    }

    public deleteBenchSensorInput(inputId: number): Observable<boolean> {
        const requestParams = new HttpParams().set("inputId", inputId.toString());
        return this.http.delete<boolean>(Urls.HITEST_DYNAMIC_TEST_BENCH, { params: requestParams });
    }

    public getDiagnosticValues(locationId: string): Observable<HiTestDiagnosticValue[]> {
        const requestHeaders = new HttpHeaders().set("locationId", locationId);
        const result = this.http.get<HiTestDiagnosticValue[]>(Urls.HITEST_GET_DIAGNOSTICS_INPUTS, { headers: requestHeaders });
        return result;
    }

    public saveDiagnosticValues(dto: HiTestDiagnosticValuesDTO): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = dto;
        return this.http.put<boolean>(Urls.HITEST_SAVE_DIAGNOSTIC_INPUTS, body, { headers: requestHeaders });
    }

    public getLocations(): Observable<IHiTestLocation[]> {
        const result = this.http.get<IHiTestLocation[]>(Urls.HITEST_GET_LOCATIONS);
        return result;
    }

    public getUserLocation(): Observable<IHiTestLocation> {
        const result = this.http.get<IHiTestLocation>(Urls.HITEST_GET_USER_LOCATION);
        return result;
    }

}
