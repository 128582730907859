/**
 * Data related to one test Module
 */
export interface IHiTestModuleModel {
    Id: number;
    TestModuleName: string;
    Description: string;
    Instruction: string;
    Ps2000Rules: string;
    ValidFrom: string; // Date;
    ValidTo: string; // Date;
    Enabled: number;
    ConfirmedToExpire: number;
    confirmedToExpireBy: string;
    LocationId: number;
    RequirementUrl: string;
    HiTestModuleRows: IHiTestModuleRowModel[];
    HiTestModuleRestrictionModules: IHiTestModuleRestriction[];
    HiTestModuleRestrictionReferencingModules: IHiTestModuleRestriction[];
    HiTestConditionModuleApproval: IHiTestConditionEntity[];
    HiTestConditionModuleCondition: IHiTestConditionEntity[];
    UnbreakableModule: number;
    LastUpdatedTimestamp: string;
    LastUpdatedUserName: string;
    RelatedFunction: string;
    FunctionalRequirementJSON: string;
    WorkFlowState: string;
    MaxRunTimeSeconds: number;
}

/**
 * Data related to one test row within a test module
 */
export interface IHiTestModuleRowModel {
    Id: number;
    TestRowName: string;
    RowTypeId: number;
    SortOrder: number;
    Description: string;
    ValidFrom: Date;
    ValidTo: Date;
    ConfirmedToExpire: number;
    ConfirmedToExpireBy: string;
    Enabled: number;
    PhraseId: number;
    ModuleId: number;
    OlpLim: number;
    NotBreakableFromParentId: number;
    InternalStepNr: number;
    HiTestConditionRowApproval: IHiTestConditionEntity[];
    HiTestConditionRowCondition: IHiTestConditionEntity[];
    Instruction: string;
    JumpIfApproved: number;
    JumpIfFailed: number;
    Ps2000Rules: string;
    HiddenFromOperator: number;
    Command: string;
    InheritConditions: number;
    HiTestConditionRowIdCommands: IHiTestConditionEntity[];
    HiTestConditionRowIdAdditionalMeasurements: IHiTestConditionEntity[];
    InstructionPl: string;
    InstructionEs: string;
    TestRowNamePl: string;
    TestRowNameEs: string;
}

export interface ITestRowViewModel extends IHiTestModuleRowModel {
    moduleName: string;
    numberOfRowsInModule: number;
    moduleDeselected: boolean;
    moduleFailed: boolean;
    placeholderForWholeModule: boolean; // If true, record will only be shown as a module header, the actual step content will be hidden
}

/**
 * Details whether a testModule must be before or after another testModule
 */
export interface IHiTestModuleRestriction {
    Id: number;
    ModuleId: number;
    ReferencingModuleId: number;
    Restriction: string;
    Data: string;
}

export interface IHiTestConditionEntity {
    Id: number;
    Criteria: string;
    Description: string;
    AutoRetry: number;
    PluginData: IHiTestPluginDataEntity[];
    ExtraDataJson: string;
}

export interface IHiTestAllowedPluginEntity {
    Id: number;
    Name: string;
}

export interface IHiTestPluginDataEntity {
    Id: number;
    ConditionId: number;
    AllowedPluginId: number;
    Data: string;
    RunBeforeMainEvent: number;     // DbTrue (-1) Indicates this plugin entry is to be executed *before* main event
}

export interface ISaveHiTestDataResponse {
    success: boolean;
    extraInformation: string;
    requestAllowSortorderRemove: boolean;
}

/**
 * The global list of testRows
 */
export interface IHiTestModuleResponse {
    HiTestModuleEntity: IHiTestModuleModel;
    AllowSortorderRemove: boolean;
}

export interface IHiTestSortorderResponse {
    HiTestModuleRowOverallSortorderEntities: IHiTestModuleRowOverallSortorderModel[];
    LocationId: number;
    LastModifiedInLocation: string;
}

/**
 * An index in the the global list of testRows
 */
export interface IHiTestModuleRowOverallSortorderModel {
    Id: number;
    TestModuleRowId: number;
    LocationId: number;
    SortorderInOverall: number;
    LastUpdatedUserName: string;
    LastUpdatedTimestamp: string;
}

export interface ILastModifiedTimestampInLocation {
    LocationId: number;
    LastModifiedTimestamp: string;
    LastModifiedUserName: string;
}

export interface IHiTestModuleRequest {
    HiTestModuleEntitys: IHiTestModuleModel[];
    HiTestModuleRowOverallSortorderEntity: IHiTestModuleRowOverallSortorderModel[];
    HiTestModuleRowOverallSortorderExpandedEntity: IHiTestModuleRowOverallSortorderModel[];
    LastModifiedTimestampInSortorderLocations: ILastModifiedTimestampInLocation[];
    HiTestRowtypeEntities: IHiTestRowtypeEntity[];
    HiTestAllowedPluginEntities: IHiTestAllowedPluginEntity[];
    OriginalDataHash: number;
    ShowRobotModeOnline: boolean;
    ShowRobotModeOffline: boolean;
    InteractiveAllowed: boolean;

    /// <summary>
    /// If current running test has more than <see cref="GuiRowLimit"/> steps overall,
    /// a function will hide steps outside of current module (for performance and clutter reasons).
    /// </summary>
    GuiRowLimit: number;

    /// <summary>
    /// When <see cref="GuiRowLimit"/> kicks in and hides rows, the <see cref="VisibleRowsRadius"/>
    /// ensures this amount of steps around the current step that are always visible (even if
    /// they belong to another module).
    /// </summary>
    VisibleRowsRadius: number;
}

export interface HiTestGroupEntity {
    Id: number;
    SyncTimestamp: Date;
    GroupType: HiTestGroupType;
    GroupName?: string;
    GroupData?: string;
}

export enum HiTestGroupType {
    Unspecified = 0,
    PS2000Group = 1
}

export interface IHiTestRowtypeEntity {
    Id: number;
    RowType: string;
    Description: string;
}

export class HiTestMockingConditions {
    public hitestMockingCondition: HiTestMockingCondition[];
}

export class HiTestMockingCondition {
    public name: string;
    public currentValue: number;
    public unit: any;
    public approveState: string;
}

/**
 * Mocks comments on a test during implementation
 * TODO: Replace with backend connection when ready
 */
export class HiTestMockedComment {
    public rowId: number;
    public user: string;
    public comment: string;
    public timestamp: string;
}

export interface EmulatedInput {
    name: string;
    value: number;
}

export interface InputDTO {
    inputName: string;
    inputTypeId: number;
    inputTypeName: string;
}

export interface ICraneEquipmentDTO {
    craneModel: string;
    craneType: string;
    orderNumber: string;
    equipments: Array<Equipment>;
    pS2000Codes: Array<string>;
    omspS2000Codes: Array<string>;
    omspS2000ToHiSet: boolean; // If true, omspS2000Codes will be used by HiSet. If set to false, pS2000Codes will be used.
    startFromRow: number;
    orderType: string;
    craneSerialNumber: string;
    itemId: string;
    controlSystem: string;
    robotMode: boolean;
    restartTestGuid: string;
    operatorLocale: string;
}

export interface IHiTestPreviousTestDTO {
    comment: string;
    craneEquipment: ICraneEquipmentDTO;
    factory: string;
    orderNo: string;
    paused: string;
    performedModules: number;
    runningTestGuid: string;
    started: string;
    testBench: string;
    totalModules: number;
    orderType: string;
    robotMode: boolean;
    completedTests: IPreviousCompletedTest[];
}

export interface IPreviousCompletedTest {
    guid: string;
    started: Date;
    ended: Date | null;
    status: string;
    testBenchName: string;
  }

export interface Equipment {
    name: string;
    selected: boolean;
    hidden: boolean;
    notCombineWith: Array<string>;
}

export interface CanWorkerConfiguration {
    channelId: number;
    serialNumber: string;
    flags: number;
    bitRate: number;
    pollDelay: number;
    readTimeout: number;
}

export interface ConnectionStatus {
    connection: string;
    connected: boolean;
    receivedData: ConnectionStatusData[];
}

export interface ConnectionStatusData {
    id: number;
    created: string;
    data: string;
}

export interface PlcWorkerConfiguration {
    ip: string;
    port: string;
    pollDelay: number;
    readTimeout: number;
}

export interface ParameterInitializationResponse {
    omsProBuilderName: string;
    aliasProBuilderName: string;
    includesDefaultParameters: boolean;
    parameters: CalculatorValue[];
    canParameters: { [id: number]: CalculatorValue[] };
}

export interface CalculatorValue {
    id: string;
    name: string;
    newValue: string;
    currentValue: string;
    selected: boolean;
    defaultParameterRule: boolean;
}


export interface modulesSelectionResponse {
    hiTestPerformedTestModules: moduleSelection[];
}

export interface moduleSelection {
    id: number;
    moduleId: number;
    moduleName: string;
    status: string;
}

export interface ModuleSelectionViewModel extends moduleSelection {
    selected: boolean;
    canModify: boolean;
}

export interface InitParametersResponseDto {
    identifier: string;
    data: ParameterInitializationResponse;
}

export interface modulesSelectionResponseDto {
    identifier: string;
    data: modulesSelectionResponse;
}

export interface localeDto {
    locale: string;
}

export interface InitParametersViewModel {
    omsProBuilderName: string;
    aliasProBuilderName: string;
    includesDefaultParameters: boolean;
    parameters: { item: CalculatorValue; selected: boolean }[];
    canParameters: { items: CalculatorValue[]; selected: boolean }[];
}

const hiTestConstants = {
    // TODO HIAA-2455 Move domain logic regarding true/false to backend https://jira.shared.tds.cargotec.com/browse/HIAA-2455
    db: {
        true: -1,
        false: 0
    },
    // TODO: Decide how to manage locations. https://jira.shared.tds.cargotec.com/browse/HIAA-2471
    location: {
        global: null, // This is intended. null = global in DB
        local: -1, // TODO: should not be -1 in the end. Might be replaced with user location if neccessary. See HIAA-2471
        unknownLocation: 1 // This is intended. 1 = unknownLocation in DB
    },
    moduleRestrictions: {
        notBefore: "NotBefore",
        notAfter: "NotAfter",
        minSkill: "MinSkil"
    },
    inputTypes: {
        spaceVariable: "SpaceVariable",
        spaceParameter: "SpaceParameter",
        tableLookup: "TableLookup",
        dynamic: "Dynamic",
        advancedExpression: "FriendlyName", // To be renamed!
        hiSetValue: "HiSetValue",
        constant: "Constant"
    },
    sensorInterfaces: {
        can: "CAN",
        plc: "PLC",
        manual: "Manual",
        empty: "Empty"
    },
    plcTypes: {
        byte: {name: "Byte", value: 0},
        word: {name: "Word", value: 1}
    },
    ps2000: {
        controlSystemEvo: "I148*",
        controlSystemX4orOlder: "I14* AND !I148*",
        empty: ""
    },
    modifierNames: {
        none: "---",
        abs: "Abs",
        add: "Add",
        subtract: "Subtract",
        divide: "Divide",
        multiply: "Multiply",
        toNumber: "ToNumber",
        bitwiseAnd: "BitwiseAnd"
    },
    languages: {
        english: "English",
        polish: "Polish",
        spanish: "Spanish"
    },
    // Language codes must match database table LANGUAGE
    languageCodes: {
        english: "en",
        polish: "pl",
        spanish: "es"
    },
    performedTest: {
        started: "Started",
        failed: "Failed",
        approved: "Approved",
        paused: "Paused",
        aborted: "Aborted",
        error: "Error"
    },

    hiTestPerformedTestModuleStatus: {
        selected: "Selected",
        deselected: "Deselected",
        started: "Started",
        failed: "Failed",
        approved: "Approved",
        skip: "Skip",
},

    canBitRates: {
        k83: "83",
        k50: "50",
        k62: "62",
        k100: "100",
        k125: "125",
        k250: "250",
        k500: "500",
        k10: "10"
    },
    serialPortBaudrates: {
        baudrate_19200: 0,
        baudrate_38400: 1,
        baudrate_57600: 2
    },
    hiTestMilSpecTriggerType: {
        mil: "Military",
        stabilizer : "Stabiliser",
        b_order : "B-order"
    },
    idOfDefaultValue: {
        UnknownTestbench: 1
    },
    exportedModuleExtension: ".testmodule",
    exportedCsvFileExtension: ".csv"
};
export { hiTestConstants as HiTestConstants };

